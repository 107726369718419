import Button from '@/components/common/Button/Button';
import routes from '@/routes';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import Head from 'next/head';
import Link from 'next/link';
import React from 'react';

import styles from './Home.module.scss';

export function Home() {
  return (
    <div className={styles.Home}>
      <Head>
        <title>Home</title>
      </Head>

      <Container className={styles['Home-Container']}>
        <Grid container spacing={1}>
          {routes
            .filter((route) => route.path !== '/')
            .map((route) => (
              <Grid key={route.path} item xs={6} sm={6} md={6} lg={4}>
                <Link href={route.path}>
                  <Card className={styles['Home-Card']}>
                    <CardContent className={styles['Home-CardContent']}>
                      <SvgIcon component={route.icon} className={styles['Home-large-icon']} />
                      <h3 className={styles['Home-page-name']}>{route.name}</h3>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            ))}
        </Grid>
      </Container>
    </div>
  );
}
